import BaseAPI from "./BaseAPI";

/**
 * Swaggers API
 * @extends BaseAPI
 */
export default class SwaggerAPI extends BaseAPI {
  /**
   * Get Swaggers from current organization
   *
   * @returns {Promise<Object[]>} Swaggers
   */
  static all(portalId) {
    return this.execute("get", `/v1/portals/${portalId}/swaggers`).then(
      data => data.results
    );
  }

  /**
   * Sync given Swaggers into resources
   *
   * @returns {Promise}
   */
  static sync(portalId, swaggers) {
    return this.execute(
      "post",
      `/v1/portals/${portalId}/swaggers/_synchronize`,
      { swaggers }
    );
  }
}
