import React, {useState, useEffect, useCallback} from 'react';
import {Link} from 'react-router-dom';
import SwaggerUI from 'swagger-ui-react';
import {FaArrowLeft, FaDownload} from 'react-icons/fa';

import Sidebar from './Sidebar';
import Markdown from './Markdown';
import VersionSelector from './Resource/VersionSelector';
import getOperationsAndModelsLinks from './utils/swagger';
import {useConfig} from './context/config';

const NAVBAR_HEIGHT = 90;

export default function Resource({match, history}) {
  const [links, setLinks] = useState([]);
  const {documentation} = useConfig();
  const {name, version} = match.params;

  const [resource, setResource] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);

  const generateLinks = (swaggerSystem) => {
    if (links.length === 0) {
      const specLinks = getOperationsAndModelsLinks(swaggerSystem);
      setLinks(specLinks);
    }
  };

  useEffect(() => {
    const tmpResource = documentation.resources.find((it) => it.name === name);
    setResource(tmpResource);
    setSelectedVersion(tmpResource ? tmpResource.versions[version] : null);
  }, [documentation, name, version]);

  const downloadSpec = () => {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,${encodeURIComponent(
        JSON.stringify(selectedVersion.spec, null, 2)
      )}`
    );
    element.setAttribute('download', `${name}-${version}.json`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <>
      <Sidebar links={links} offset={NAVBAR_HEIGHT} />
      <div className="doc-portal-main">
        {selectedVersion ? (
          <>
            <div className="doc-portal-resource-nav">
              <Link to="/">
                <FaArrowLeft
                  className="doc-portal-resource-nav-previous"
                  style={{width: '20px', height: '20px'}}
                />
              </Link>
              <VersionSelector
                resource={name}
                current={version}
                versions={resource.versions}
                history={history}
              />
              <div className="doc-portal-resource-nav-download">
                <FaDownload onClick={downloadSpec} />
              </div>
            </div>
            <div className="doc-portal-container">
              {selectedVersion.markdowns.map((md, index) => (
                <Markdown key={md.id} md={md} />
              ))}
              <br />
              {selectedVersion.spec && (
                <SwaggerUI
                  spec={selectedVersion.spec}
                  onComplete={generateLinks}
                  docExpansion="list"
                />
              )}
            </div>
          </>
        ) : (
          <div className="doc-portal-content doc-portal-container">
            <h1>Unknown version {version}</h1>
            <Link to="/">Go back home</Link>
          </div>
        )}
      </div>
    </>
  );
}
