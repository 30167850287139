import React, {useEffect} from 'react';
import {useAsync} from 'react-async';
import {Redirect} from 'react-router-dom';
import {Store} from 'react-notifications-component';
import DocumentationAPI from '../api/DocumentationAPI';
import defaultLogo from '../logo.svg';
import DocPortal from './DocPortal/DocPortal';
import Home from './DocPortal/Home';
import FullPageLoading from './FullPageLoading';

import {useAuth} from '../contexts/AuthContext';

const fetchDocumentation = () => DocumentationAPI.get();
const fetchPublicDocumentation = () => DocumentationAPI.getPublic().catch((err) => {});
export default function Portal() {
  const {user, isPortalAdmin = false} = useAuth();

  const {
    data: documentation,
    isLoading,
    error,
  } = useAsync(user ? fetchDocumentation : fetchPublicDocumentation);

  useEffect(() => {
    if (window.location.search.includes('login-success=false')) {
      Store.addNotification({
        title: 'An error occured',
        message: 'Login failed',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  }, []);

  if (error && error.response) {
    if (error.response.status === 400) {
      return <Redirect to="/admin" />;
    }
  }
  if (isLoading) {
    return <FullPageLoading label="Fetching your portal..." />;
  }

  return (
    <>
      {documentation && (
        <DocPortal
          logo={documentation.logo || defaultLogo}
          documentation={documentation}
          isAdmin={isPortalAdmin}
        />
      )}
      {!documentation && <Home />}
    </>
  );
}
