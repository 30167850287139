import {helpers} from 'swagger-client';

export default function getOperationsAndModelsLinks({specSelectors}) {
  const taggedOps = specSelectors.taggedOperations();
  const operationsLinks = taggedOps.reduce((links, tagObj, tag) => {
    const operations = tagObj.get('operations');
    const childrenOut = operations.reduce((children, op) => {
      const path = op.get('path');
      const escapedTag = tag.replace(/\s/g, '_');
      const method = op.get('method');
      const opID = helpers.opId(op, path, method);
      children.push({
        name: path,
        method,
        href: `operations-${escapedTag}-${opID}`,
      });
      return children;
    }, []);
    links.push({name: tag, href: null, children: childrenOut});
    return links;
  }, []);

  let models = {};
  const modelsPath = ['components', 'schemas'];
  const spec = specSelectors.specJson();
  if (spec.hasIn(modelsPath)) {
    models = spec.getIn(modelsPath).toJSON();
  }

  const modelsLinks = {
    name: 'Models',
    href: null,
    children: Object.keys(models).map((model) => ({
      name: model,
      href: `model-${model}`,
    })),
  };
  return [...operationsLinks, modelsLinks];
}
