import React from 'react';

export default function FilteredLinks({links, search, children}) {
  const searchRegex = new RegExp(search, 'i');
  const filteredLinks = links.reduce((acc, link) => {
    const hasChildren = link.children.length > 0;
    const filteredLink = {...link};
    if (hasChildren) {
      // Filter children matching
      filteredLink.children = link.children.filter(({name}) => name.match(searchRegex));
    }
    if (filteredLink.children.length) {
      acc.push(filteredLink);
    }
    return acc;
  }, []);

  return <>{children(filteredLinks)}</>;
}
