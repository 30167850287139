import React, {Suspense} from 'react';
import 'easymde/dist/easymde.min.css';
import MdToJsx from 'markdown-to-jsx';
import ReactDOMServer from 'react-dom/server';

const SimpleMDE = React.lazy(() => import('react-simplemde-editor'));

export default function MarkdownEditor({value, name, onChange}) {
  const onEditorChange = (newValue) => onChange({target: {name, value: newValue}});
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SimpleMDE
        value={value}
        onChange={onEditorChange}
        options={{
          spellChecker: false,
          previewRender(text) {
            return ReactDOMServer.renderToString(<MdToJsx>{text}</MdToJsx>);
          },
        }}
      />
    </Suspense>
  );
}
