import React, {useContext} from 'react';
import {
  Home as HomeIcon,
  List as ListIcon,
  Link as LinkIcon,
  Public as PublicIcon,
  TextFormat as TextIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandIcon,
  ExitToApp as ExitIcon,
} from '@material-ui/icons';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';

import {Link} from 'react-router-dom';
import {PortalContext} from '../../contexts/PortalContext';

export default function Menu() {
  const {currentPortal} = useContext(PortalContext);

  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <img src="/images/logo.png" alt="logo" className={classes.logo} />
      <Divider />
      <List>
        <Link to="/admin" className={classes.drawerLink}>
          <ListItem button>
            <ListItemIcon className={classes.drawerIcon}>
              <PublicIcon />
            </ListItemIcon>
            <ListItemText primary="Portals" />
          </ListItem>
        </Link>

        {currentPortal && (
          <>
            <Divider />
            <ListItem>
              <ListItemIcon className={classes.drawerIcon}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Configuration" />
              <ExpandIcon className={classes.drawerIcon} />
            </ListItem>
            <List component="div" disablePadding>
              <Link
                to={`/admin/portals/${currentPortal.id}`}
                className={classes.drawerLink}
              >
                <ListItem button className={classes.subDrawer}>
                  <ListItemIcon className={classes.drawerIcon}>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText primary="Resources" />
                </ListItem>
              </Link>
              <Link
                to={`/admin/portals/${currentPortal.id}/homepage`}
                className={classes.drawerLink}
              >
                <ListItem button className={classes.subDrawer}>
                  <ListItemIcon className={classes.drawerIcon}>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Homepage" />
                </ListItem>
              </Link>
              <Link
                to={`/admin/portals/${currentPortal.id}/markdowns`}
                className={classes.drawerLink}
              >
                <ListItem button className={classes.subDrawer}>
                  <ListItemIcon className={classes.drawerIcon}>
                    <TextIcon />
                  </ListItemIcon>
                  <ListItemText primary="Markdowns" />
                </ListItem>
              </Link>
              <Link
                to={`/admin/portals/${currentPortal.id}/shareable-tokens`}
                className={classes.drawerLink}
              >
                <ListItem button className={classes.subDrawer}>
                  <ListItemIcon className={classes.drawerIcon}>
                    <LinkIcon />
                  </ListItemIcon>
                  <ListItemText primary="Shareable Tokens" />
                </ListItem>
              </Link>
            </List>
          </>
        )}
        <Link to="/logout" className={classes.drawerLink}>
          <ListItem button>
            <ListItemIcon className={classes.drawerIcon}>
              <ExitIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Link>
      </List>
    </Drawer>
  );
}

export const menuWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: menuWidth,
    color: theme.palette.common.white,
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: menuWidth,
  },
  drawerLink: {
    textDecoration: 'none',
    color: theme.palette.common.white,
  },
  drawerIcon: {
    color: theme.palette.common.white,
  },
  subDrawer: {
    paddingLeft: theme.spacing(4),
  },
  logo: {
    width: '205px',
    alignSelf: 'center',
  },
}));
