import BaseAPI from './BaseAPI';

/**
 * Portal API
 * @extends BaseAPI
 */
export default class PortalShareableTokenAPI extends BaseAPI {
  /**
   * Get portal shareable tokens for given portal
   * @param {String} portalId Portal id
   * @returns {Promise<Object[]>} Portal
   */
  static all(portalId) {
    return this.execute('get', `/v1/portals/${portalId}/shareable-tokens`);
  }

  /**
   * Create portal shareable token for given portal
   * @param {String} portalId Portal id
   * @param {Object} shareableToken Portal id
   * @returns {Promise<Object[]>} Portal
   */
  static create(portalId, shareableToken) {
    return this.execute(
      'post',
      `/v1/portals/${portalId}/shareable-tokens`,
      shareableToken
    );
  }

  /**
   * Get a portal shareable token by id for a given portal
   *
   * @param {String} portalId Portal id
   * @param {String} id shareableToken id
   * @returns {Promise<Object>} Portal
   */
  static get(portalId, id) {
    return this.execute('get', `/v1/portals/${portalId}/shareable-tokens/${id}`);
  }

  /**
   * Update a portal shareable token
   *
   * @param {string} portalId Portal id
   * @param {String} id shareableToken id
   * @param {Object} shareableToken shareableToken update
   * @returns {Promise<Object>} Updated portal
   */
  static update(portalId, id, shareableToken) {
    return this.execute(
      'put',
      `/v1/portals/${portalId}/shareable-tokens/${id}`,
      shareableToken
    );
  }

  /**
   * Delete a portal shareable token
   *
   * @param {string} portalId Portal id
   * @param {String} id shareableToken id
   * @returns {Promise<Object>}
   */
  static delete(portalId, id) {
    return this.execute('delete', `/v1/portals/${portalId}/shareable-tokens/${id}`);
  }
}
