import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core';
import React, {useState, useContext} from 'react';
import {useAsync} from 'react-async';
import MarkdownAPI from '../../../api/MarkdownAPI';
import {PortalContext} from '../../../contexts/PortalContext';

const fetchMarkdowns = ({customerId}) => MarkdownAPI.all(customerId);

export default function MarkdownPicker({match, history, onClose, onSave}) {
  const {currentPortal} = useContext(PortalContext);
  const classes = useStyles();
  const {data: markdowns = []} = useAsync(fetchMarkdowns, {
    customerId: currentPortal.customer.id,
  });
  const [selectedMarkdown, setSelectedMarkdown] = useState('');

  const handleChange = ({target}) => {
    setSelectedMarkdown(target.value);
  };
  const handleSave = () => {
    const markdown = markdowns.find(({id}) => id === selectedMarkdown);
    if (markdown) {
      onSave(markdown);
    }
  };
  const redirectToCreate = () => {
    history.push(`/admin/portals/${match.params.id}/markdowns/create`);
  };
  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add markdown</DialogTitle>
      <DialogContent>
        <DialogContentText>Pick from an existing one :</DialogContentText>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="markdown">Markdown</InputLabel>
          <Select
            value={selectedMarkdown}
            onChange={handleChange}
            inputProps={{name: 'markdown', id: 'markdown'}}
          >
            {markdowns.map((markdown) => (
              <MenuItem key={markdown.id} value={markdown.id}>
                {markdown.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DialogContentText>Or create a new one</DialogContentText>
        <Button variant="outlined" onClick={redirectToCreate} color="primary">
          Create
        </Button>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={selectedMarkdown === ''}
          onClick={handleSave}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 300,
  },
}));
