import {
  Button,
  Card,
  Container,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  OpenInNew as OpenIcon,
  FileCopy as ContentCopyIcon,
} from '@material-ui/icons';
import dayjs from 'dayjs';

import copy from 'copy-to-clipboard';
import {Store} from 'react-notifications-component';
import React, {useContext} from 'react';
import {useAsync} from 'react-async';
import {getCurrentDomain, getFullUrl} from '../../utils/domain';
import Head from './Head';
import {PortalContext} from '../../contexts/PortalContext';
import PortalShareableTokenAPI from '../../api/PortalShareableTokenAPI';

const domain = getCurrentDomain();

const loadShareableTokens = ({portalId}) => PortalShareableTokenAPI.all(portalId);

export default function ShareableTokens({history, match}) {
  const classes = useStyles();
  const {currentPortal} = useContext(PortalContext);
  const {data: shareableTokens = [], reload} = useAsync(loadShareableTokens, {
    portalId: currentPortal.id,
  });
  const hasShareableTokens = shareableTokens.length > 0;
  const addShareableToken = () => history.push(`${match.url}/create`);
  const editShareableToken = (id) => history.push(`${match.url}/${id}/edit`);
  const deleteShareableToken = async (id) => {
    await PortalShareableTokenAPI.delete(currentPortal.id, id);
    reload();
  };

  const getFullPortalUrl = (shareableToken) => {
    return getFullUrl(`${currentPortal.name}-${domain}`, {token: shareableToken.value});
  };

  const openPortal = (shareableToken) => {
    window.open(getFullPortalUrl(shareableToken), '_blank');
  };

  const copyUrlToClipboard = (shareableToken) => {
    copy(getFullPortalUrl(shareableToken));

    Store.addNotification({
      message: 'Url copied to clipboard',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  return (
    <>
      <Head>Shareable tokens</Head>
      <Container>
        <Card className={classes.home}>
          <div className={classes.tableHead}>
            <Typography component="h3" variant="h6">
              Your Shareable tokens
            </Typography>
            <div className={classes.tableActions}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={addShareableToken}
              >
                Add Shareable token
              </Button>
            </div>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Expiration Date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shareableTokens.map((shareableToken) => (
                <TableRow key={shareableToken.id}>
                  <TableCell component="th" scope="row">
                    {shareableToken.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {shareableToken.value}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {shareableToken.expirationDate &&
                      dayjs(shareableToken.expirationDate).format('DD/MM/YYYY HH:mm')}
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    <Tooltip title="Edit">
                      <IconButton
                        aria-label="Edit"
                        onClick={() => editShareableToken(shareableToken.id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Copy link">
                      <IconButton
                        aria-label="copy"
                        onClick={() => copyUrlToClipboard(shareableToken)}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Open link">
                      <IconButton
                        aria-label="Goto"
                        onClick={() => openPortal(shareableToken)}
                      >
                        <OpenIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        aria-label="Delete"
                        onClick={() => deleteShareableToken(shareableToken.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {!hasShareableTokens && (
                <TableRow>
                  <TableCell colSpan="3" component="th" scope="row">
                    <i>No Shareable Token yet</i>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Card>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  tableHead: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  home: {
    backgroundColor: theme.palette.common.white,

    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
  },
}));
