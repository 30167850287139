import {Chip, IconButton, makeStyles, Tooltip, Typography} from '@material-ui/core';
import {grey} from '@material-ui/core/colors';
import {
  Delete as DeleteIcon,
  Description as DescriptionIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import React from 'react';

export default function Spec({spec, onEditClick, onDeleteClick}) {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div>
        <DescriptionIcon className={classes.icon} />
      </div>
      <div className={classes.content}>
        {spec.type === 'APIZRIO_SWAGGER' && (
          <Typography className={classes.title}>
            APIZR.io Swagger: {spec.title}
          </Typography>
        )}
        {spec.type === 'APIZRIO_TAGS' && (
          <div className={classes.tags}>
            <Typography className={classes.title}>apigrow Tags:</Typography>
            {spec.content.map((tag) => (
              <Chip key={`${tag}`} className={classes.tag} color="primary" label={tag} />
            ))}
          </div>
        )}
        {spec.type === 'FILE' && (
          <Typography className={classes.title}>Swagger File</Typography>
        )}

        <div>
          <Tooltip title="Edit">
            <IconButton aria-label="Edit" onClick={onEditClick}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={onDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '1.9rem',
    marginRight: theme.spacing(2),
  },
  main: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: '3px solid #E8ECF0',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  title: {
    paddingLeft: theme.spacing(1.5),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    color: grey[600],
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  tag: {
    marginLeft: theme.spacing(1),
  },
}));
