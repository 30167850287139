import React, {useState} from 'react';
import {HashLink} from 'react-router-hash-link';
import {FaAngleDown, FaAngleRight} from 'react-icons/fa';
import LinkMethod from './LinkMethod';

// Custom scroll function to add offset
const scrollWithOffset = (el, offset) => {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: 'smooth',
  });
};

export default function SidebarLink({link, level = 0, offset = 0}) {
  const {name, href, children = [], method} = link;
  const [toggled, setToggle] = useState(false);
  const hasChildren = children.length > 0;
  const isChild = level > 0;
  const linkClass = `doc-portal-link ${hasChildren ? 'doc-portal-link-parent' : ''}  ${
    isChild ? 'doc-portal-link-child' : ''
  }`;
  const ToggleArrow = toggled ? FaAngleRight : FaAngleDown;
  const toggle = () => {
    if (hasChildren) {
      setToggle(!toggled);
    }
  };
  return (
    <>
      <div
        className={linkClass}
        onClick={toggle}
        onKeyPress={() => {}}
        role="button"
        tabIndex="0"
      >
        {hasChildren && <ToggleArrow className="doc-portal-link-toggle" />}
        {method && <LinkMethod method={method} />}
        {href ? (
          <HashLink to={`#${href}`} scroll={(el) => scrollWithOffset(el, offset)}>
            {name}
          </HashLink>
        ) : (
          <div>{name}</div>
        )}
      </div>
      {!toggled &&
        children.map((child) => (
          <SidebarLink
            key={`${child.name} ${child.method ? `_${child.method}` : ''}`}
            link={child}
            level={level + 1}
            offset={offset}
          />
        ))}
    </>
  );
}
