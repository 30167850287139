import {Button, Card, Container, makeStyles, TextField} from '@material-ui/core';
import React, {useEffect, useState, useContext} from 'react';
import MarkdownAPI from '../../../api/MarkdownAPI';
import Head from '../Head';
import MarkdownEditor from '../MarkdownEditor';
import {PortalContext} from '../../../contexts/PortalContext';

export default function EditMarkdown({history, match}) {
  const {currentPortal} = useContext(PortalContext);

  const isEditing = match.params.markdownId || false;
  const markdownId = isEditing ? match.params.markdownId : null;
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    async function fetchMarkdown() {
      const markdown = await MarkdownAPI.get(currentPortal.customer.id, markdownId);
      setTitle(markdown.title);
      setContent(markdown.content);
    }
    if (markdownId) {
      fetchMarkdown();
    }
  }, [markdownId, currentPortal.customer.id]);

  const onSubmit = async (event) => {
    event.preventDefault();
    if (isEditing) {
      await MarkdownAPI.update(currentPortal.customer.id, markdownId, {
        title,
        content,
      });
    } else {
      await MarkdownAPI.create(currentPortal.customer.id, {title, content});
    }

    history.goBack();
  };

  return (
    <>
      <Head>{isEditing ? 'Edit Markdown' : 'New Markdown'}</Head>
      <Container>
        <Card className={classes.home}>
          <form className={classes.form} noValidate onSubmit={onSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="title"
              label="Title"
              name="title"
              autoComplete="title"
              autoFocus
              value={title}
              onChange={({target}) => setTitle(target.value)}
            />

            <MarkdownEditor
              value={content}
              name="content"
              onChange={({target}) => setContent(target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Save
            </Button>
          </form>
        </Card>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  home: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
  },
}));
