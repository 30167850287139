import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  makeStyles,
} from '@material-ui/core';
import React, {useState, useContext, useEffect} from 'react';
import SwaggerAPI from '../../../api/SwaggerAPI';
import {PortalContext} from '../../../contexts/PortalContext';

export default function SyncSwaggers({onClose, onSync}) {
  const {currentPortal} = useContext(PortalContext);

  const classes = useStyles();
  const [swaggersToSync, setSwaggersToSync] = useState([]);

  useEffect(() => {
    async function fetchSwaggers() {
      const swaggers = await SwaggerAPI.all(currentPortal.id);
      setSwaggersToSync(swaggers);
    }
    fetchSwaggers();
  }, [currentPortal.id]);

  const toggleSwagger = (swagger) => {
    if (swaggersToSync.some(({id}) => swagger.id === id)) {
      setSwaggersToSync(swaggersToSync.filter(({id}) => swagger.id !== id));
    } else {
      setSwaggersToSync([...swaggersToSync, swagger]);
    }
  };

  const syncSwaggers = async () => {
    await SwaggerAPI.sync(currentPortal.id, swaggersToSync);
    onSync();
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Sync swaggers from APIZR.io</DialogTitle>
      <DialogContent>
        <DialogContentText>Synchronize your swaggers to your resources</DialogContentText>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell align="right">Tags</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {swaggersToSync.map((swagger) => {
              const isSelected = swaggersToSync.some(({id}) => swagger.id === id);

              return (
                <TableRow
                  hover
                  key={swagger.id}
                  onClick={() => toggleSwagger(swagger)}
                  role="checkbox"
                  aria-checked={isSelected}
                  selected={isSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected}
                      color="primary"
                      onChange={() => toggleSwagger(swagger)}
                    />
                  </TableCell>
                  <TableCell scope="row">{swagger.title}</TableCell>
                  <TableCell align="right">
                    {swagger.tags &&
                      swagger.tags.map((tag) => (
                        <Chip
                          size="small"
                          key={tag}
                          className={classes.tag}
                          label={tag}
                          color="primary"
                        />
                      ))}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={swaggersToSync.length === 0}
          onClick={syncSwaggers}
          color="primary"
          variant="outlined"
        >
          Synchronize
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  tag: {
    margin: theme.spacing(0.5),
  },
}));
