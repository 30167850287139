import React, {useState, createContext, useMemo} from 'react';

export const PortalContext = createContext({
  currentPortal: null,
  setCurrentPortal: () => {},
});

export default function PortalProvider({children}) {
  const [currentPortal, setCurrentPortal] = useState(null);
  const portalContext = useMemo(
    () => ({currentPortal, setCurrentPortal}),
    [currentPortal, setCurrentPortal]
  );
  return (
    <PortalContext.Provider value={portalContext}>{children}</PortalContext.Provider>
  );
}
