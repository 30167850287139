import {IconButton, TableCell, TableRow, Tooltip} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import React, {useState} from 'react';
import EditableVersion from './EditableVersion';

export default function VersionRow({version, onChange, onConfigure, onDelete}) {
  const [editing, setEditing] = useState(false);

  const updateVersion = (updatedVersion) => {
    setEditing(false);
    onChange({...version, ...updatedVersion});
  };

  if (editing) {
    return (
      <EditableVersion
        defaultVersion={version}
        onSave={updateVersion}
        onCancel={() => setEditing(false)}
      />
    );
  }
  return (
    <TableRow>
      <TableCell scope="row">{version.name}</TableCell>
      <TableCell align="right" scope="row" style={{display: 'flex'}}>
        <Tooltip title="Delete">
          <IconButton aria-label="Delete" onClick={() => onDelete(version)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton aria-label="Edit" onClick={() => setEditing(true)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Configure">
          <IconButton aria-label="Configure" onClick={() => onConfigure(version)}>
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
