import React, {useContext} from 'react';

const ConfigContext = React.createContext({
  logo: null,
  documentation: {},
  isAdmin: false,
});

export const useConfig = () => {
  return useContext(ConfigContext);
};

export default ConfigContext;
