import {createTheme} from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#324157',
    },
    secondary: {
      main: '#FFF',
    },
    background: {
      default: '#EAEFF1',
    },
  },
});

export default theme;
