import {
  Card,
  Container,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {Settings as SettingsIcon, OpenInNew as OpenIcon} from '@material-ui/icons';
import React from 'react';
import {useAsync} from 'react-async';
import PortalAPI from '../../api/PortalAPI';
import DeleteButton from './DeleteButton';
import Head from './Head';
import {getCurrentDomain, getFullUrl} from '../../utils/domain';

const domain = getCurrentDomain();
const loadPortals = () => PortalAPI.all();

const useStyles = makeStyles((theme) => ({
  tableHead: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  home: {
    backgroundColor: theme.palette.common.white,

    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function Portals({history}) {
  const classes = useStyles();

  const {data: portals = [], reload} = useAsync(loadPortals);

  const configurePortal = (portalId) => {
    history.push(`/admin/portals/${portalId}`);
  };
  const deletePortal = async (portalId) => {
    await PortalAPI.delete(portalId);
    reload();
  };

  const openPortal = async (portal) => {
    window.open(getFullUrl(`${portal.name}-${domain}`), '_blank');
  };

  return (
    <>
      <Head>Portals</Head>
      <Container>
        <Card className={classes.home}>
          <div className={classes.tableHead}>
            <Typography component="h3" variant="h6">
              Your portals
            </Typography>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {portals.map((portal) => (
                <TableRow key={portal.id}>
                  <TableCell component="th" scope="row">
                    {portal.name}
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    <Tooltip title="Configure">
                      <IconButton
                        aria-label="Configure"
                        onClick={() => configurePortal(portal.id)}
                      >
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Open">
                      <IconButton aria-label="Open" onClick={() => openPortal(portal)}>
                        <OpenIcon />
                      </IconButton>
                    </Tooltip>
                    <DeleteButton onDelete={() => deletePortal(portal.id)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Container>
    </>
  );
}
