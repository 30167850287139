import React, {useEffect} from 'react';
import {Container} from 'react-bootstrap';
import Loader from './Loader';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3002';

export default function Login() {
  useEffect(() => {
    window.location.replace(
      `${API_URL}/v1/oauth/login?redirect_uri=${new URL(window.location).origin}`
    );
  }, []);
  return (
    <Container style={{display: 'flex', justifyContent: 'center'}}>
      <Loader />
    </Container>
  );
}
