export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve([reader.result, file.type]);
    reader.onerror = (error) => reject(error);
  });
}

export function resize(file) {
  return new Promise((resolve, reject) => {
    // Create image to receive base64 image of data
    const ImageIn = new Image();
    // file is in base64
    ImageIn.src = file;
    ImageIn.onload = () => {
      const canvas = document.createElement('canvas');

      // Set maxWidth and maxHeight
      const maxW = 300;
      const maxH = 300;

      let {width, height} = ImageIn;

      if (width > height && width > maxW) {
        height *= maxW / width;
        width = maxW;
      } else if (height > maxH) {
        width *= maxH / height;
        height = maxH;
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(ImageIn, 0, 0, width, height);

      // Parse resize picture to base 64 into data
      const fileOut = canvas.toDataURL(ImageIn.type);
      resolve(fileOut.split(',')[1]);
    };
    ImageIn.onerror = (error) => reject(error);
  });
}
