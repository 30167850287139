import React, {useEffect} from 'react';
import FullPageLoading from './FullPageLoading';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3002';

export default function Logout() {
  useEffect(() => {
    window.location.replace(`${API_URL}/v1/oauth/logout`);
  }, []);

  return <FullPageLoading label="Logging out..." />;
}
