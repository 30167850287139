import {
  Button,
  Container,
  Fab,
  IconButton,
  makeStyles,
  Snackbar,
  Typography,
} from '@material-ui/core';
import {green} from '@material-ui/core/colors';
import {Navigation as NavigationIcon, Close as CloseIcon} from '@material-ui/icons';
import React, {useContext, useState} from 'react';
import {useAsync} from 'react-async';
import PortalAPI from '../../api/PortalAPI';
import ResourceAPI from '../../api/ResourceAPI';
import {PortalContext} from '../../contexts/PortalContext';
import Head from './Head';
import Resource from './Resources/Resource';
import SyncSwaggers from './Resources/SyncSwaggers';

const loadResources = ({portalId, customerId}) =>
  Promise.all([ResourceAPI.all(customerId), PortalAPI.get(portalId)]);

export default function Resources({history, match, location}) {
  const classes = useStyles();
  const {currentPortal} = useContext(PortalContext);
  const [showSync, setShowSync] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [resourcesToPublish, setResourcesToPublish] = useState([]);
  const [resources, setResources] = useState([]);

  const {data = [], reload} = useAsync(loadResources, {
    onResolve: ([tmpResources, portal]) => {
      const resourcesToPublishTmp =
        portal.resources.length > 0
          ? portal.resources.map(({id, visibility = 'ALL'}) => ({
              id,
              visibility,
            }))
          : [];

      setResourcesToPublish(resourcesToPublishTmp);

      tmpResources.forEach((resource) => {
        const portalResource = portal.resources.find((it) => it.id === resource.id);
        if (portalResource) {
          // eslint-disable-next-line no-param-reassign
          resource.visibility = portalResource.visibility || 'PRIVATE_ONLY';
        } else {
          // eslint-disable-next-line no-param-reassign
          resource.visibility = 'PRIVATE_ONLY';
        }
      });

      setResources(tmpResources);

      if (resources.length === 0 && location.state && location.state.showSync) {
        setShowSync(true);
      }
    },
    portalId: currentPortal.id,
    customerId: currentPortal.customer.id,
  });

  const [, portal = {resources: [], name: ''}] = data;
  const hasResources = resources.length > 0;

  const addResource = () => history.push(`${match.url}/resources/create`);
  const deleteResource = async (id) => {
    await ResourceAPI.delete(currentPortal.customer.id, id);
    reload();
  };

  const onSwaggersSync = () => {
    setShowSync(false);
    reload();
  };

  const setVisibility = (resourceId, visibility) => {
    setResources(
      resources.map((it) => ({
        ...it,
        visibility: it.id === resourceId ? visibility : it.visibility,
      }))
    );
  };

  const toggleResource = (resource, {target}) => {
    if (target.checked) {
      setResourcesToPublish([...resourcesToPublish, resource]);
    } else {
      setResourcesToPublish(resourcesToPublish.filter((it) => it.id !== resource.id));
    }
  };

  const publish = async () => {
    await PortalAPI.update(currentPortal.id, {
      resources: resourcesToPublish.map((it) => {
        const myResource = resources.find((el) => el.id === it.id);
        return {
          id: it.id,
          visibility: myResource && myResource.visibility ? myResource.visibility : 'ALL',
        };
      }),
    });
    setShowSuccessMessage(true);

    reload();
  };

  return (
    <>
      <Head>Resources</Head>
      <Container>
        <div className={classes.resourcesActions}>
          <Button variant="contained" color="primary" onClick={addResource}>
            Add Resource
          </Button>
          <Button variant="contained" color="primary" onClick={() => setShowSync(true)}>
            Sync swaggers from APIZR.io
          </Button>
        </div>
        {hasResources && (
          <div className={classes.resourcesList}>
            {resources.map((resource) => {
              const isPublished = portal.resources
                .map((it) => it.id)
                .includes(resource.id);

              const isChecked = !!resourcesToPublish.find((it) => it.id === resource.id);
              return (
                <Resource
                  key={resource.id}
                  history={history}
                  match={match}
                  resource={resource}
                  isPublished={isPublished}
                  isChecked={isChecked}
                  onCheck={(e) => toggleResource(resource, e)}
                  onChange={reload}
                  onDelete={deleteResource}
                  setVisibility={setVisibility}
                />
              );
            })}
          </div>
        )}
        {!hasResources && (
          <Typography component="h5" variant="h6">
            <i>No resources yet</i>
          </Typography>
        )}

        {showSync && (
          <SyncSwaggers onClose={() => setShowSync(false)} onSync={onSwaggersSync} />
        )}
        <Fab
          className={classes.publishButton}
          variant="extended"
          onClick={publish}
          color="primary"
        >
          <NavigationIcon className={classes.publishIcon} />
          Publish to {portal.name} portal ({resourcesToPublish.length})
        </Fab>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={showSuccessMessage}
          autoHideDuration={3000}
          onClose={() => setShowSuccessMessage(false)}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Resources published</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={() => setShowSuccessMessage(false)}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  resourcesActions: {
    display: 'flex',
    margin: theme.spacing(3, 0),
    '& button': {
      marginRight: theme.spacing(3),
    },
  },
  resourcesList: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  publishButton: {
    margin: 0,
    top: 'auto',
    left: '45%',
    bottom: theme.spacing(5),
    right: 'auto',
    position: 'fixed',
    backgroundColor: green[500],
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  publishIcon: {
    marginRight: theme.spacing(1),
  },
}));
