import React from 'react';
import MdToJsx from 'markdown-to-jsx';
import PropTypes from 'prop-types';

const Markdown = ({md}) => {
  return <MdToJsx className="doc-portal-content">{md}</MdToJsx>;
};

Markdown.propTypes = {
  md: PropTypes.string.isRequired,
};

export default Markdown;
