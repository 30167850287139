import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
} from '@material-ui/core';
import React, {useState} from 'react';
import TagsForm from './SpecPicker/TagsForm';
import SwaggerForm from './SpecPicker/SwaggerForm';
import FileForm from './SpecPicker/FileForm';

export default function SpecPicker({defaultSpec = null, onClose, onSave}) {
  const classes = useStyles();
  const isEditing = defaultSpec !== null;
  const [selectedType, setSelectedType] = useState(isEditing ? defaultSpec.type : null);
  const [spec, setSpec] = useState(defaultSpec);
  const handleSave = () => {
    onSave(spec);
  };
  const typeForm = (type) => {
    if (type === 'FILE') {
      return <FileForm spec={spec} onChange={setSpec} />;
    }
    if (type === 'APIZRIO_TAGS') {
      return <TagsForm spec={spec} onChange={setSpec} />;
    }
    if (type === 'APIZRIO_SWAGGER') {
      return <SwaggerForm spec={spec} onChange={setSpec} />;
    }
    return '';
  };
  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {isEditing ? 'Edit' : 'Add'} Swagger Spec
      </DialogTitle>
      <DialogContent style={{width: '500px'}}>
        <DialogContentText>Choose type :</DialogContentText>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectedType === 'FILE'}
                  onChange={() => setSelectedType('FILE')}
                  value="FILE"
                />
              }
              label="File"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectedType === 'APIZRIO_TAGS'}
                  onChange={() => setSelectedType('APIZRIO_TAGS')}
                  value="APIZRIO_TAGS"
                />
              }
              label="APIZR.io Swagger by tags"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectedType === 'APIZRIO_SWAGGER'}
                  onChange={() => setSelectedType('APIZRIO_SWAGGER')}
                  value="APIZRIO_SWAGGER"
                />
              }
              label="APIZR.io Swagger by id"
            />
          </FormGroup>
        </FormControl>
        {typeForm(selectedType)}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={!selectedType || !spec}
          onClick={handleSave}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1, 0),
  },
}));
