import React, {useState} from 'react';
import {Tooltip, IconButton} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Check as CheckIcon,
  Cancel as CancelIcon,
} from '@material-ui/icons';

export default function DeleteButton({onDelete}) {
  const [confirm, setConfirm] = useState(false);
  const confirmDelete = () => {
    setConfirm(false);
    onDelete();
  };

  if (confirm) {
    return (
      <>
        <Tooltip title="Cancel">
          <IconButton aria-label="Cancel" onClick={() => setConfirm(false)}>
            <CancelIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Confirm">
          <IconButton aria-label="Confirm" onClick={confirmDelete}>
            <CheckIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  }
  return (
    <Tooltip title="Delete">
      <IconButton aria-label="Delete" onClick={() => setConfirm(true)}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
}
