import BaseAPI from './BaseAPI';

/**
 * User API
 * @extends BaseAPI
 */
export default class UserAPI extends BaseAPI {
  static getCurrent(shareableToken) {
    return this.execute(
      'get',
      `/v1/users/me${shareableToken ? `?shareableToken=${shareableToken}` : ''}`
    );
  }
}
