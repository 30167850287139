import React, {useState} from 'react';
import {
  Button,
  TextField,
  Chip,
  makeStyles,
  Divider,
  Typography,
} from '@material-ui/core';

export default function TagsForm({spec = null, onChange}) {
  const classes = useStyles();
  const defaultTags = spec && Array.isArray(spec.content) ? spec.content : [];
  const [tags, setTags] = useState(defaultTags);
  const inputRef = React.createRef();

  const addTag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newTags = [...tags, inputRef.current.value];
    setTags(newTags);
    inputRef.current.value = '';
    onChange({type: 'APIZRIO_TAGS', content: newTags});
  };

  const deleteTag = (deletedTag) => {
    const newTags = tags.filter((tag) => tag !== deletedTag);
    setTags(newTags);
    onChange({type: 'APIZRIO_TAGS', content: newTags});
  };

  return (
    <>
      <Divider className={classes.divider} />
      <Typography variant="h6">Tags :</Typography>
      <div className={classes.tags}>
        {tags.map((tag) => (
          <Chip
            key={`${tag}`}
            className={classes.tag}
            color="primary"
            label={tag}
            onDelete={() => deleteTag(tag)}
          />
        ))}
      </div>
      <form onSubmit={addTag} className={classes.form}>
        <TextField
          variant="outlined"
          margin="normal"
          className={classes.input}
          required
          fullWidth
          name="content"
          label="Add new tag"
          type="text"
          id="tag"
          inputRef={inputRef}
        />
        <Button type="submit">Add</Button>
      </form>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2, 0),
  },
  form: {
    display: 'flex',
    margin: theme.spacing(1, 0),
  },
  input: {
    flex: 1,
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tag: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
