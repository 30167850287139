import React from 'react';
import Select from 'react-select';
import {sortVersions} from '../utils/versions';

export default function VersionSelector({
  resource = '',
  current = null,
  versions = [],
  history,
}) {
  const versionsOptions = sortVersions(versions).map((version) => ({
    value: version,
    label: version,
  }));

  const onVersionChange = (version) => {
    history.push(`/resources/${resource}/${version.value}`);
  };

  return (
    <>
      <span>Versions :</span>
      <Select
        className="doc-portal-version-selector"
        value={{value: current, label: current}}
        onChange={onVersionChange}
        options={versionsOptions}
        isDisabled={versionsOptions.length <= 1}
      />
    </>
  );
}
