import React, {useState, useEffect, useContext} from 'react';
import {
  Chip,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  makeStyles,
  Divider,
  Typography,
  Radio,
} from '@material-ui/core';
import {PortalContext} from '../../../../contexts/PortalContext';
import SwaggerAPI from '../../../../api/SwaggerAPI';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2, 0),
  },
  form: {
    display: 'flex',
    margin: theme.spacing(1, 0),
  },
  input: {
    flex: 1,
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tag: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function SwaggerForm({spec = null, onChange}) {
  const classes = useStyles();
  const [swaggers, setSwaggers] = useState([]);
  const {currentPortal} = useContext(PortalContext);
  const defaultSelectedSwaggers =
    spec && typeof spec.content === 'string' ? spec.content : null;
  const [selectedSwagger, setSelectedSwagger] = useState(defaultSelectedSwaggers);
  useEffect(() => {
    async function fetchSwaggers() {
      const allSwaggers = await SwaggerAPI.all(currentPortal.id);
      setSwaggers(allSwaggers);
    }
    fetchSwaggers();
  }, [currentPortal.id]);

  const selectSwagger = (swagger) => {
    setSelectedSwagger(swagger.id);
    onChange({
      type: 'APIZRIO_SWAGGER',
      content: {id: swagger.id, moduleId: swagger.moduleId},
      title: swagger.title,
    });
  };

  return (
    <>
      <Divider className={classes.divider} />
      <Typography variant="h6">Select a swagger</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell align="right">Tags</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {swaggers &&
            swaggers.map((swagger) => {
              const isChecked = swagger.id === selectedSwagger;
              return (
                <TableRow
                  hover
                  key={swagger.id}
                  onClick={() => selectSwagger(swagger)}
                  role="radio"
                  aria-checked={isChecked}
                  selected={isChecked}
                >
                  <TableCell padding="checkbox">
                    <Radio
                      checked={isChecked}
                      color="primary"
                      onChange={() => selectSwagger(swagger)}
                    />
                  </TableCell>
                  <TableCell scope="row">{swagger.title}</TableCell>
                  <TableCell align="right">
                    {swagger.tags &&
                      swagger.tags.map((tag) => (
                        <Chip
                          size="small"
                          key={tag}
                          className={classes.tag}
                          label={tag}
                          color="primary"
                        />
                      ))}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </>
  );
}
