import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {FaArrowRight} from 'react-icons/fa';
import capitalize from '../utils/format';
import {getLastVersion} from '../utils/versions';

function ResourcesSummary({resources}) {
  return (
    <div className="doc-portal-content">
      {resources && resources.length > 0 && <h2 id="resources">Resources</h2>}
      <div className="doc-portal-summary">
        {resources.map((resource) => {
          const lastVersion = getLastVersion(resource.versions);
          return (
            <Link
              className="doc-portal-resource"
              key={resource.id}
              to={`/resources/${resource.name}/${lastVersion}`}
              id={`resources_${resource.name}`}
            >
              <div className="doc-portal-resource-name">{capitalize(resource.name)}</div>

              <p className="doc-portal-resource-description">
                {resource.versions[lastVersion].spec &&
                  resource.versions[lastVersion].spec.info &&
                  resource.versions[lastVersion].spec.info.description}
              </p>
              <FaArrowRight
                className="doc-portal-resource-arrow"
                style={{height: '20px', width: '20px'}}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
}

ResourcesSummary.propTypes = {
  resources: PropTypes.array,
};

export default ResourcesSummary;
