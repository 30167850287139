import BaseAPI from "./BaseAPI";

/**
 * Markdown API
 * @extends BaseAPI
 */
export default class MarkdownAPI extends BaseAPI {
  /**
   * Get all markdowns for current organization
   *
   * @returns {Promise<Object[]>} Markdowns
   */
  static all(customerId) {
    return this.execute("get", `/v1/customers/${customerId}/markdowns`);
  }

  /**
   * Get Markdown
   *
   * @returns {Promise<Object>} Markdown
   */
  static get(customerId, id) {
    return this.execute("get", `/v1/customers/${customerId}/markdowns/${id}`);
  }

  /**
   * Create a Markdown
   *
   * @returns {Promise<Object>} Markdown
   */
  static create(customerId, markdown) {
    return this.execute(
      "post",
      `/v1/customers/${customerId}/markdowns`,
      markdown
    );
  }

  /**
   * Update a Markdown
   *
   * @returns {Promise<Object>} Markdown
   */
  static update(customerId, id, markdown) {
    return this.execute(
      "put",
      `/v1/customers/${customerId}/markdowns/${id}`,
      markdown
    );
  }

  /**
   * Delete a Markdown
   *
   * @returns {Promise<Object>} Markdown
   */
  static delete(customerId, id) {
    return this.execute(
      "delete",
      `/v1/customers/${customerId}/markdowns/${id}`
    );
  }
}
