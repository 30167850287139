import {
  Button,
  Card,
  Container,
  makeStyles,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import React, {useEffect, useState, useContext} from 'react';
import {MuiPickersUtilsProvider, DateTimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Head from '../Head';
import {PortalContext} from '../../../contexts/PortalContext';
import PortalShareableTokenAPI from '../../../api/PortalShareableTokenAPI';

export default function AddOrEditShareableToken({history, match}) {
  const {currentPortal} = useContext(PortalContext);

  const isEditing = match.params.shareableTokenId || false;
  const shareableTokenId = isEditing ? match.params.shareableTokenId : null;
  const classes = useStyles();
  const [name, setName] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [hasExpirationDate, setHasExpirationDate] = useState(false);

  useEffect(() => {
    async function fetchShareableToken() {
      const shareableToken = await PortalShareableTokenAPI.get(
        currentPortal.id,
        shareableTokenId
      );
      setName(shareableToken.name);
      setExpirationDate(shareableToken.expirationDate);
      setHasExpirationDate(!!shareableToken.expirationDate);
    }
    if (shareableTokenId) {
      fetchShareableToken();
    }
  }, [shareableTokenId, currentPortal.id]);

  const onSubmit = async (event) => {
    event.preventDefault();
    const content = {
      name,
      ...(hasExpirationDate &&
        expirationDate.toISOString().length > 0 && {
          expirationDate: expirationDate.toISOString(),
        }),
      ...(!hasExpirationDate && {expirationDate: 'never'}),
    };

    if (isEditing) {
      await PortalShareableTokenAPI.update(currentPortal.id, shareableTokenId, content);
    } else {
      await PortalShareableTokenAPI.create(currentPortal.id, content);
    }

    history.goBack();
  };

  return (
    <>
      <Head>{isEditing ? 'Edit shareable token' : 'New shareable token'}</Head>
      <Container>
        <Card className={classes.home}>
          <form className={classes.form} noValidate onSubmit={onSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="title"
              label="Name"
              name="title"
              autoComplete="title"
              autoFocus
              value={name}
              onChange={({target}) => setName(target.value)}
            />
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasExpirationDate}
                    color="primary"
                    onChange={({target}) => {
                      setHasExpirationDate(target.checked);
                    }}
                  />
                }
                label="Set an expiration date"
              />
            </div>
            {hasExpirationDate && (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  margin="normal"
                  ampm={false}
                  id="date-picker-dialog"
                  label="Expiration date"
                  format="dd/MM/yyyy"
                  value={expirationDate}
                  onChange={(date) => setExpirationDate(date)}
                  KeyboardButtonProps={{'aria-label': 'change date'}}
                  size="small"
                />
              </MuiPickersUtilsProvider>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {isEditing ? 'Save' : 'Add'}
            </Button>
          </form>
        </Card>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  home: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
  },
}));
