import {makeStyles} from '@material-ui/core';
import React from 'react';
import {Switch, Redirect} from 'react-router-dom';
import PortalProvider from '../contexts/PortalContext';
import ConfigPortal from './Admin/ConfigPortal';
import Menu, {menuWidth} from './Admin/Menu';
import Portals from './Admin/Portals';
import EditPortal from './Admin/Portals/EditPortal';

import withMainTheme from './WithMainTheme';
import AdminRoute from './Admin/AdminRoute';

const NotFoundRedirect = (props) => {
  return <Redirect to="/admin" />;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  main: {
    width: `calc(100% - ${menuWidth}px)`,
  },
}));

function Admin() {
  const classes = useStyles();

  return (
    <PortalProvider>
      <div className={classes.root}>
        <Menu />
        <main className={classes.main}>
          <Switch>
            <AdminRoute exact path="/admin" component={Portals} />
            <AdminRoute exact path="/admin/:id/edit" component={EditPortal} />
            <AdminRoute path="/admin/portals/:id" component={ConfigPortal} />
            <AdminRoute component={NotFoundRedirect} />
          </Switch>
        </main>
      </div>
    </PortalProvider>
  );
}

export default withMainTheme(Admin);
