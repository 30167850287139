import React from 'react';
import {ThemeProvider} from '@material-ui/styles';
import {CssBaseline} from '@material-ui/core';
import theme from '../styles/MainTheme';

export default function withMainTheme(WrappedComponent) {
  const wrappedComponent = ({...props}) => (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <WrappedComponent {...props} />
    </ThemeProvider>
  );

  return wrappedComponent;
}
