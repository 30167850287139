import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import UserAPI from '../api/UserAPI';
import AccountAPI from '../api/AccountAPI';
import FullPageLoading from '../components/FullPageLoading';

export const AuthContext = createContext({
  isLoggedIn: false,
  user: null,
  justLoggedOut: false,
  login: () => {},
  logout: () => {},
});

export default function AuthProvider({children}) {
  const [user, setUser] = useState(null);
  const [isPortalAdmin, setIsPortalAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchUser() {
    try {
      const url = new URL(window.location);
      const shareableToken = url.searchParams.get('token');

      const {isPortalAdmin: _isPortalAdmin, ...rest} = await UserAPI.getCurrent(
        shareableToken
      );
      setUser(rest);
      setIsPortalAdmin(_isPortalAdmin);
    } catch (err) {
      setUser(null);

      throw new Error(err.response?.data?.message ?? err.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function getUser() {
      try {
        await fetchUser();
      } catch (err) {
        // Fail silently
      }
    }

    getUser();
  }, []);

  const authContext = useMemo(() => ({user, isPortalAdmin}), [user, isPortalAdmin]);

  if (isLoading) {
    return <FullPageLoading label="Authenticating..." />;
  }

  return (
    <AuthContext.Provider value={authContext}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
