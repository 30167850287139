import React, {useState, useEffect, useContext} from 'react';
import {Button, Container, Card, makeStyles, TextField} from '@material-ui/core';
import Head from '../Head';
import ResourceAPI from '../../../api/ResourceAPI';
import {PortalContext} from '../../../contexts/PortalContext';

export default function EditResource({history, match}) {
  const {currentPortal} = useContext(PortalContext);
  const isEditing = match.params.resourceId || false;
  const resourceId = isEditing ? match.params.resourceId : null;
  const classes = useStyles();
  const [name, setName] = useState('');

  useEffect(() => {
    async function fetchResource() {
      const resource = await ResourceAPI.get(currentPortal.customer.id, resourceId);
      setName(resource.name);
    }
    if (resourceId) {
      fetchResource();
    }
  }, [resourceId, currentPortal.customer.id]);

  const onSubmit = async (event) => {
    event.preventDefault();
    if (isEditing) {
      await ResourceAPI.update(currentPortal.customer.id, resourceId, {name});
    } else {
      await ResourceAPI.create(currentPortal.customer.id, {name});
    }

    history.goBack();
  };

  return (
    <>
      <Head>{isEditing ? 'Edit resource' : 'New resource'}</Head>
      <Container>
        <Card className={classes.home}>
          <form className={classes.form} noValidate onSubmit={onSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={name}
              onChange={({target}) => setName(target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Save
            </Button>
          </form>
        </Card>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  home: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
  },
}));
