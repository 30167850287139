import React from 'react';
import {makeStyles, Divider, Typography, Button} from '@material-ui/core';

export default function FileForm({spec = null, onChange}) {
  const classes = useStyles();

  const onFileRead = ({target}) => {
    onChange({type: 'FILE', content: target.result});
  };
  const upload = ({target}) => {
    const file = target.files[0];
    const reader = new FileReader();
    reader.onload = onFileRead;
    reader.readAsText(file);
  };

  return (
    <>
      <Divider className={classes.divider} />
      <Typography variant="h6">Swagger File (JSON or YAML)</Typography>
      <Button variant="contained" color="primary" component="label">
        Upload spec
        <input
          accept="application/json,text/json,text/yaml,text/vnd.yaml,text/x-yaml,application/x-yaml"
          className={classes.inputFile}
          style={{display: 'none'}}
          id="raised-button-file"
          type="file"
          onChange={upload}
        />
      </Button>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2, 0),
  },
  form: {
    display: 'flex',
    margin: theme.spacing(1, 0),
  },
  input: {
    flex: 1,
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tag: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
