import {
  Button,
  Card,
  Chip,
  Container,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import {green, red} from '@material-ui/core/colors';
import {
  Done as DoneIcon,
  HighlightOff as OffIcon,
  ImportContactsOutlined as PortalIcon,
} from '@material-ui/icons';
import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import * as yup from 'yup';
import PortalAPI from '../../../api/PortalAPI';
import {getCurrentDomain} from '../../../utils/domain';

const schema = yup
  .string()
  .required('Name is required')
  .matches(/^[a-zA-Z]+$/, 'Only text characters (a-z, A-Z)');

const domain = getCurrentDomain();

function EditPortal({history, match}) {
  const classes = useStyles();
  const isEditing = match.params.id || false;
  const portalId = isEditing ? match.params.id : null;
  const [name, setName] = useState('');
  const [available, setAvailable] = useState(null);
  const [errors, setErrors] = useState([]);
  const hasErrors = errors.length > 0;

  useEffect(() => {
    async function fetchPortal() {
      const portal = await PortalAPI.get(portalId);
      setName(portal.name);
    }
    if (portalId) {
      fetchPortal();
    }
  }, [portalId]);

  const checkName = async ({target}) => {
    setName(target.value);
    try {
      await schema.validate(target.value);
      setErrors([]);
      const {available: newAvailable} = await PortalAPI.checkAvailability(target.value);
      setAvailable(newAvailable);
    } catch (err) {
      if (err.errors) {
        setErrors(err.errors);
      }
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (isEditing) {
      await PortalAPI.update(portalId, {name});
      history.push('/admin');
    } else {
      try {
        const portal = await PortalAPI.create({name});
        setErrors([]);
        history.push({
          pathname: `/admin/portals/${portal.id}`,
          state: {
            showSync: true,
          },
        });
      } catch (err) {
        setErrors(['Free account can only have one portal']);
      }
    }
  };

  return (
    <Container>
      <Card className={classes.main}>
        <div className={classes.iconContainer}>
          <PortalIcon className={classes.icon} />
        </div>
        <Typography className={classes.title} component="h1" variant="h4">
          {isEditing ? 'Edit' : 'Create'} your portal
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <div className={classes.portalInput}>
            <TextField
              id="filled-name"
              label="Name"
              className={classes.textField}
              InputProps={{
                className: classes.textField,
              }}
              autoFocus
              error={hasErrors}
              helperText={hasErrors ? errors[0] : 'Dev, prod etc..'}
              onChange={checkName}
              value={name}
              margin="normal"
              variant="filled"
            />
            <div
              style={{
                borderTopRightRadius: '13px',
                borderBottomRightRadius: '13px',
              }}
              className={classes.inputAddon}
            >
              -{domain}
            </div>
          </div>

          <div className={classes.availability}>
            {available !== null && (
              <>
                {available ? (
                  <Chip
                    className={classes.available}
                    icon={<DoneIcon className={classes.availableIcon} />}
                    label="Available"
                  />
                ) : (
                  <Chip
                    className={classes.unavailable}
                    icon={<OffIcon className={classes.availableIcon} />}
                    label="Unavailable"
                  />
                )}
              </>
            )}
          </div>

          <Button
            type="submit"
            variant="contained"
            disabled={available !== true || hasErrors}
            color="primary"
          >
            {isEditing ? 'Edit' : 'Create'}
          </Button>
        </form>
      </Card>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
    marginBottom: theme.spacing(3),
  },
  iconContainer: {
    padding: theme.spacing(2),
    borderWidth: '2px',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderRadius: '75px',
    marginBottom: theme.spacing(3),
  },
  icon: {
    height: '5rem',
    width: '5rem',
  },
  portalInput: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  textField: {
    backgroundColor: theme.palette.common.white,
    '&:hover, &:focus, &:active': {
      backgroundColor: theme.palette.common.white,
    },
  },
  inputAddon: {
    fontWeight: 500,
    padding: '8px 12px',
    marginTop: '16px',
    marginBottom: '8px',
    backgroundColor: theme.palette.grey[200],
  },
  availability: {
    marginBottom: theme.spacing(2),
  },
  available: {
    fontSize: '1rem',
    backgroundColor: green[400],
    color: theme.palette.common.white,
  },
  availableIcon: {
    color: theme.palette.common.white,
  },
  unavailable: {
    fontWeight: 500,
    fontSize: '1.1rem',
    backgroundColor: red[400],
    color: theme.palette.common.white,
  },
}));

export default withRouter(EditPortal);
