// Return organization + domain of current url
// Ex: camaieu.portal.local.io
export function getCurrentDomain() {
  const urlPattern = new RegExp(process.env.REACT_APP_URL_PATTERN);

  const [, , organization = '', domain = ''] =
    window.location.hostname.match(urlPattern) || [];
  return organization + domain;
}

export function getFullUrl(url, params) {
  const {location} = window;

  const outputUrl = new URL(
    `${location.protocol}//${url}${location.port ? `:${location.port}` : ''}`
  );

  if (params) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, val] of Object.entries(params)) {
      outputUrl.searchParams.append(key, val);
    }
  }

  return outputUrl.toString();
}
