import BaseAPI from './BaseAPI';

/**
 * Resource API
 * @extends BaseAPI
 */
export default class ResourceAPI extends BaseAPI {
  /**
   * Get resources for current organization
   *
   * @returns {Promise<Object[]>} Resources
   */
  static all(customerId) {
    return this.execute('get', `/v1/customers/${customerId}/resources`);
  }

  /**
   * Get resource
   *
   * @returns {Promise<Object>} Resourc
   */
  static get(customerId, id) {
    return this.execute('get', `/v1/customers/${customerId}/resources/${id}`);
  }

  /**
   * Create a resource
   *
   * @returns {Promise<Object>} Resources
   */
  static create(customerId, resource) {
    return this.execute('post', `/v1/customers/${customerId}/resources`, resource);
  }

  /**
   * Update a resource
   *
   * @returns {Promise<Object>} Resource
   */
  static update(customerId, id, resource) {
    return this.execute('put', `/v1/customers/${customerId}/resources/${id}`, resource);
  }

  /**
   * Delete a resource
   *
   * @returns {Promise<Object>} Resource
   */
  static delete(customerId, id) {
    return this.execute('delete', `/v1/customers/${customerId}/resources/${id}`);
  }
}
