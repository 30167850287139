import React from 'react';
import {Route, Redirect, useHistory} from 'react-router-dom';
import {useAuth} from '../../contexts/AuthContext';

const AdminRoute = ({component: Component, ...rest}) => {
  const {isPortalAdmin} = useAuth();
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={(props) =>
        isPortalAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: history.location.pathname,
                message: 'Unauthorized, you must be an admin of your organization.',
              },
            }}
          />
        )
      }
    />
  );
};

export default AdminRoute;
