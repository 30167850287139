import BaseAPI from "./BaseAPI";

/**
 * Version  API
 * @extends BaseAPI
 */
export default class VersionAPI extends BaseAPI {
  /**
   * Get a version
   *
   * @returns {Promise<Object>} Version
   */
  static get(resourceId, versionId) {
    return this.execute(
      "get",
      `/v1/resources/${resourceId}/versions/${versionId}`
    );
  }

  /**
   * Update a version
   *
   * @returns {Promise<Object>} Version
   */
  static update(resourceId, versionId, version) {
    return this.execute(
      "put",
      `/v1/resources/${resourceId}/versions/${versionId}`,
      version
    );
  }

  /**
   * Create a version
   *
   * @returns {Promise<Object>} Version
   */
  static create(resourceId, version) {
    return this.execute(
      "post",
      `/v1/resources/${resourceId}/versions`,
      version
    );
  }

  /**
   * Delete a version
   *
   * @returns {Promise<Object>} Version
   */
  static delete(resourceId, versionId) {
    return this.execute(
      "delete",
      `/v1/resources/${resourceId}/versions/${versionId}`
    );
  }
}
